body {
    margin: 0;
}

.main-layout {
    min-height: 100vh;
}

.main-layout .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.main-layout .trigger:hover {
    color: #1890ff;
}

.main-layout .logo {
    height: 32px;
    margin: 16px;
    padding-bottom: 60px;
}
