// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

@font-face {
  font-family: "Poppins", sans-serif;
  src: url('./assets/fonts/DesignerVN-Poppins-ExtraBold.ttf') format('truetype'),
  url('./assets/fonts/DesignerVN-Poppins-Regular.ttf') format('truetype');
}

@mixin fs-from-class($class) {
  $size: str-slice($class, 4);
  font-size: $size + px;
}

@mixin w-from-class($class) {
  $size: str-slice($class, 3);
  font-size: $size + px;
}

$font-sizes: 12, 14, 16, 18, 20, 24, 26, 28, 30, 32, 36, 38, 40;
$font-weights: 100, 200, 300, 400, 500, 600, 700;

$poppins: 'Poppins', sans-serif;
$manrope: 'Manrope', sans-serif;
$background: #F0EBEB;
$primaryColor: #2F9ABC;
$dangerColor: #DD5656;
$goldColor: #C2AF44;
$yellow: #E9D154;
$rightSide: #F6F2DD;
$disableColor: #CCCCCC;
$txtRed: #FF0000;
$txtBlue: #3A93AF;
$txtGreen: #3C652F;
$txtMuted: #979CA5;

body {
  font-family: $poppins;

  .content-page {
    margin: 24px 16px 0;
  }
}

.bg-r-side {
  background-color: $rightSide;
  padding: 20px;
  position: sticky;
  top: 0;

  .circle {
    background-color: white;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.md-wrap {
  img {
    max-width: 100%;
  }
}

.success {
  color: green;
}

.danger {
  color: red;
}

.txt-blue {
  color: $txtBlue;
}

.txt-muted {
  color: $txtMuted;
}

.link {
  text-decoration: underline;
  color: #121212;
  font-size: 20px;
}

.pl-0 {
  padding-left: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-3 {
  margin-bottom: 30px;
}

.bold {
  font-weight: bold;
}

.bg-yellow {
  background-color: $yellow;
}

.yellow {
  color: $yellow;
}

.box-side {
  margin-bottom: 30px;
}

@each $size in $font-sizes {
  .fs-#{$size} {
    font-size: #{$size}px;
  }
}

@each $weight in $font-weights {
  .w-#{$weight} {
    font-weight: #{$weight};
  }
}

.manrope {
  font-family: $manrope;
}

.chat-item {
  border-radius: 16px;
  padding: 10px 5px 10px 10px;
  background: #F7F7F7;
  margin-bottom: 13px;
}

.sidebar {
  background-color: white;
  padding: 20px;
  min-height: 100vh;
  position: sticky;
  top: 0;

  &.fixed {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.course-view {

  .box {
    // margin-bottom: 30px;
    // padding-bottom: 45px;
    .content {
      padding-bottom: 25px;
      //max-height: 100vh;
      //overflow-y: scroll;
    }

    p {
      color: #696984;
    }

    &.white {
      background: white;
      padding: 10px;
      border-radius: 20px;
    }

    .warn {
      background: #F48C06;
      padding: 20px;
      line-height: 27px;
      font-size: 18px;
      font-family: $poppins;
    }

    .blue {
      background: #9DCCFF;
      padding: 20px;
      line-height: 27px;
      font-size: 18px;
      font-family: $poppins;
    }

    .error {
      background: #EE645B;
      padding: 20px;
      line-height: 27px;
      font-size: 18px;
      font-family: $poppins;
    }

    .desc {
      font-size: 18px;
      color: rgba(105, 105, 132, 1);
    }

    h2 {
      font-size: 30px;
      font-weight: 600;
      font-family: $poppins;
      margin-bottom: 0;
    }
  }
}

.note-item {
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 12px;
  background: white;
}

.video-wrap {
  position: relative;
  overflow: hidden;

  .video-content {
    min-height: 500px;
    height: 100%;
  }

  .quiz-inside {
    z-index: 999;
    padding: 20px;
    // position: absolute;
    background: white;
    width: 100%;
    height: 100%;
  }

  &:hover, &:focus {
    .controls {
      bottom: 0;
    }
  }

  .controls {
    z-index: 100;
    height: 65px;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 5px 10px;
    color: white;
    transition: 0.3s all;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

.lesson-item {
  background: rgba(179, 182, 182, .5);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: $poppins;
  padding: 8px;
  cursor: pointer;
  color: #252641;

  a {
    color: #252641
  }

  &.blue {
    background: rgba(157, 204, 255, .3);
  }

  .img {
    width: 24px;
    display: flex;
  }

  .limit {
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &:hover, &.active {
    background-color: $yellow;

    .limit {
      color: white;
    }

    svg path {
      fill: white;
    }
  }
}

.p-0 {
  padding: 0;
}

.pb-5 {
  padding-bottom: 50px;
}

.mb-38 {
  margin-bottom: 38px;
}

.ml-1 {
  margin-left: 10px;
}

.m-0 {
  margin: 0;
}

.bg-white {
  background-color: white;

  &.shadown {
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.box-chat {
  border-radius: 16px;
  border: 1px solid #ccc;
}

.lesson-list {
  list-style: none;
  padding-left: 20px;

  li {
    margin-bottom: 19px;
  }
}

.p-3 {
  padding: 5px 7px;
}

.btn {
  border-radius: 5px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
}

.course-box {
  background-color: #F7F7F7;
  padding: 12px 20px;
  margin: 15px 0 30px 0;
  border: 1px solid #7A7A7A;
}

.gold {
  background-color: $goldColor;
  color: white;
}

.gray {
  background-color: #ABAAA6;
  color: white;
}

.btn-default {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #515A6A;

  &.un-register {
    background-color: #ccc;
  }
}

.my-10 {
  margin: 10px 0;
}

.bg-transparent {
  .ant-collapse-content {
    background: transparent;
    border: none;
  }

  .ant-collapse-header {
    align-items: center !important;
  }
}

.w-100 {
  width: 100%;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.column-item {
  width: 100%;
}

.mb-25 {
  margin-bottom: 25px;
}

.pl-40 {
  padding-left: 50px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-50 {
  margin-bottom: 50px;
}

.true-button {
  width: 148px;
  height: 36px;
}

.text-right {
  text-align: right;
}

.txt-dark {
  color: black;
}

.w-24 {
  width: 50px;
}

.underline {
  text-decoration: underline;
}

.header-modal {
  border-bottom: 1px solid #878787;
  margin-bottom: 30px;
  padding: 0 0 15px 0;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
}

.text-center {
  text-align: center;
}

.d-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.flex-column {
    flex-direction: column;
  }

  &.flex-start {
    justify-content: start;
  }
}

.title {
  font-size: 26px;
  color: #121212;
  font-family: $poppins;
  font-weight: 700;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
  padding-bottom: 10px;
  background-color: #FBFAF4;
}

.card-box {
  border-radius: 15px;
  padding: 20px;
  background: white;
}

.chat-room {
  background-color: white;
  min-height: 50vh;
  border: 1px solid #ccc;
  border-radius: 15px;

  .user-list {
    border-radius: 15px;
    border-right: 1px solid #ccc;
    height: 100%;
    background-color: white;

    .item {
      cursor: pointer;
      padding-top: 20px;

      &.active {
        background-color: lightblue;
      }
    }
  }

  .chat-list {
    position: relative;

    .chat-content {
      max-height: 80vh;
      padding-right: 10px;
      overflow-y: scroll;

      .item {
        margin-top: 25px;
        padding: 8px;
        border-radius: 5px;

        &.active {
          background-color: rgba(201, 200, 200, 0.55);
        }
      }
    }
  }
}

.heading {
  display: flex;
  justify-content: space-between;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #FBFAF4;
  }

  .heading-title {
    font-family: $poppins;
    color: #000000;
    font-weight: 700;
    padding: 0;
    margin: 0;
    font-size: 44px;
  }

  .sub-title {
    font-family: $poppins;
    color: $txtMuted;
    margin: 0;
  }
}

.resizeContainer {
  .resizeInner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: var(--background-color);
    transition: background-color 0.2s linear;

    .resizeIcon {
      width: 1em;
      height: 1em;
      position: absolute;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
    }
  }
}

.loading-hint {
  display: flex;
  height: 100vh;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading {
    display: flex;
    flex-direction: row;
  }

  .loading__letter {
    font-size: 35px;
    margin-top: 30px;
    font-weight: normal;
    letter-spacing: 4px;
    color: #1262de;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .loading__letter:nth-child(2) {
    animation-delay: .1s;
  }

  .loading__letter:nth-child(3) {
    animation-delay: .2s;
  }

  .loading__letter:nth-child(4) {
    animation-delay: .3s;
  }

  .loading__letter:nth-child(5) {
    animation-delay: .4s;
  }

  .loading__letter:nth-child(6) {
    animation-delay: .5s;
  }

  .loading__letter:nth-child(7) {
    animation-delay: .6s;
  }

  .loading__letter:nth-child(8) {
    animation-delay: .8s;
  }

  .loading__letter:nth-child(9) {
    animation-delay: 1s;
  }

  .loading__letter:nth-child(10) {
    animation-delay: 1.2s;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px)
    }
    40% {
      transform: translateY(-40px);
    }
    80%,
    100% {
      transform: translateY(0px);
    }
  }
}

.ant-collapse-header {
  align-items: center;
}

.box-messages {
  min-height: calc(100% - 60px);
  position: relative;
  margin-bottom: 10px;

  .input {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.lg-box {

}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6cad96; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ecab9; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb; //rgba(20,105,69,.2);
  }
}


.chat {
  .chat-bubble {
    background-color: #e6f8f1;
    padding: 16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display: inline-block;
  }

  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }

  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6cad96; //rgba(20,105,69,.7);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }

  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }

  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }

  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }

  .typing .dot:last-child {
    margin-right: 0;
  }
}


.small-box {
  z-index: 99;
  position: fixed;
  right: 10px;
  bottom: 0;
  width: 400px;
  max-width: 400px;

  .box-messages {
    min-height: 350px;
  }
}
